import React, { FC } from 'react';

import Typography from 'components/elements/Typography';

import { IPropsArticleTextSection } from './models';

import './ArticleTextSection.scss';
import './ShadowArticleTextSection.scss';

const ArticleTextSection: FC<IPropsArticleTextSection> = ({ data }) => (
  <div data-testid="ArticleTextSection" className="article-text-section">
    {data?.sectionTitle?.[0] ? <Typography data={data.sectionTitle} /> : null}
    {data?.sectionSubTitle?.[0] ? <Typography data={data.sectionSubTitle} /> : null}
    <Typography data={data.sectionBody} />
  </div>
);

export default ArticleTextSection;
